:root {
  --active_stoke: #4ab819;
  --active_fill: #f4f7f9;
}

#menu #hidden-menu,
#menu #hidden-menu * {
  visibility: hidden;
  position: relative;
  z-index: -1;
  width: 0px;
}
#menu #hidden-menu {
  top: 120px;
}

#menu #hidden-menu .submenu ul li {
  height: 60px;
}

#menu #hidden-menu .submenu ul {
  padding-bottom: 150px;
}

#menu .sidebar-overflow-container {
  overflow-x: hidden;
  width: 330px;
  height: 100%;
}

#menu .sidebar-content {
  width: 330px;
  display: flex;
  background-color: #f4f7f9;
  position: relative;
  overflow: hidden;
  height: 100%;
}
#menu .sidebar-content:hover {
  overflow-y: auto;
}
#menu .homeMenu {
  width: 90px;
}
.customize {
  border-radius: 4px;
  border: 1px solid #4ab819;
  margin: 1.25rem 1.875rem 0 1.875rem;
  font-size: 0.875rem;
  text-align: center;
}

.customize a {
  padding: 6px 1rem;
  font-size: 0.875rem;
  line-height: 1;
  font-weight: 600;
}

.customize a,
.submenu .customize a:hover {
  color: #4ab819;
}

.delete-account-lazy {
  color: #2b3238;
  text-decoration: none;
  font-weight: normal;
  font-size: 14px;
  display: inline-block;
  padding: 20px 30px;
  width: 100%;
  text-align: left;
}

@media (min-width: 1024px) {
  #sidebar-toggle:not(:checked) + .sidebar-content {
    width: 90px;
  }
  #menu {
    height: 100%;
    overflow-y: auto;
  }
}

@media (max-width: 1023px) {
  #sidebar-toggle:not(:checked) + .sidebar-content,
  #sidebar-toggle:not(:checked) + .sidebar-content * {
    width: 330px;
  }
}

#sidebar-toggle {
  display: none;
}

#menu ul.menu-items {
  width: 90px;
  font-size: 12px;
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
  list-style: none;
  padding: 0;
  margin: 0;
  min-height: 100%;
  height: fit-content;
  background-color: #181912;
  z-index: 3;
}

#menu ul.menu-items > li:first-child {
  height: 80px;
}

#menu ul.menu-items > li:first-child > a {
  padding: 0px;
  display: block;
  height: 100%;
}

#menu ul.menu-items > li:last-child {
  border-bottom: 1px solid rgb(184 184 181 / 20%);
}

#menu ul.menu-items > li:first-child img {
  position: relative;
}

#menu ul.menu-items > li {
  border-top: 1px solid #000;
  border-bottom: 1px solid rgb(184 184 181 / 20%);
  width: 90px;
  height: 90px;
  transition: padding 0.2s ease-in-out, border 0.2s linear;
  text-align: center;
}

#menu ul.menu-items > li > a {
  color: #787872;
  text-decoration: none;
  width: 100%;
  display: inline-block;
  line-height: 1;
  background-repeat: no-repeat;
  background-position-y: 20px;
  background-position-x: 50%;
  word-break: break-word;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100%;
}

#menu ul.menu-items > li > a > span {
  margin-top: 10px;
}

#menu ul.menu-items > li.active {
  box-shadow: -9px 15px 9px 0 rgba(0, 0, 0, 0.16);
  border: none;
  border-bottom-left-radius: 5px;
  border-top-left-radius: 5px;
  background-color: #f4f7f9;
}

#menu ul.menu-items > li.active:not(.user) {
  border-bottom: 1px solid #1e2629;
}
#menu ul.menu-items > li.active > a {
  color: #2b3238;
}

#menu ul.menu-items:hover > li.active:not(:hover) {
  box-shadow: none;
  border-radius: 0;
  background: none;
}

#menu ul.menu-items:hover > li.active:not(:hover) > a {
  color: #787872;
}

#menu ul.menu-items:hover > li.active:last-child {
  border-bottom: none;
}

#menu ul.menu-items > li.active:not(.user) + li {
  border-top-color: transparent;
}

#menu .menu-items > li.user {
  height: 0;
  margin-left: -20000%;
  border: none;
}

#menu .home-menu {
  font-size: 0;
  display: none;
}
#menu .user-hover a.icon-user-hover {
  display: none;
}

.build-version {
  position: absolute;
  bottom: 0;
  color: transparent;
  font-size: 10px;
  left: 0;
}

/* Icons */

.icon-dashboard {
  background-image: url("images/Home.svg");
}

.active .icon-dashboard {
  background-image: url("images/home-active.svg");
}
#menu ul.menu-items:hover > li.active:not(:hover) .icon-dashboard {
  background-image: url("images/Home.svg");
}

.active .icon-catalogue * {
  stroke: var(--active_stoke, #4ab819);
}

#menu ul.menu-items:hover > li.active:not(:hover) .icon-catalogue * {
  stroke: #787872;
}

.active .icon-orders * {
  stroke: var(--active_stoke, #4ab819);
  fill: var(--active_fill, #f4f7f9);
}
#menu ul.menu-items:hover > li.active:not(:hover) .icon-orders * {
  stroke: #787872;
  fill: transparent;
}
#menu ul.menu-items:hover > li.active:not(:hover) .icon-orders svg g g path {
  fill: #33353a;
}
.icon-home {
  background-image: url("images/transparent_short_logo.webp");
  background-position: center !important;
  background-size: cover;
}

.active .icon-logistics *,
.active .icon-delivery * {
  stroke: var(--active_stoke, #4ab819);
}

#menu ul.menu-items:hover > li.active:not(:hover) .icon-logistics *,
#menu ul.menu-items:hover > li.active:not(:hover) .icon-delivery * {
  stroke: #787872;
}

.active .icon-marketing * {
  stroke: var(--active_stoke, #4ab819);
}

#menu ul.menu-items:hover > li.active:not(:hover) .icon-marketing * {
  stroke: #787872;
}

.active .icon-customers * {
  stroke: var(--active_stoke, #4ab819);
}

#menu ul.menu-items:hover > li.active:not(:hover) .icon-customers * {
  stroke: #787872;
}

.active .icon-analytics * {
  stroke: var(--active_stoke, #4ab819);
}

.active .icon-settings * {
  stroke: var(--active_stoke, #4ab819);
}

#menu ul.menu-items:hover > li.active:not(:hover) .icon-settings * {
  stroke: #787872;
}

.icon-language .DropDown,
.icon-profile-image:not(.profile-img) .DropDown {
  top: -100%;
}

.icon-profile-image svg {
  margin-top: 50%;
  transform: translateY(-50%);
}

.icon-stores {
  background-image: url("images/icon-stores-inactive.svg");
}

.active .icon-stores {
  background-image: url("images/icon-stores-active.svg");
}

.active .icon-staff * {
  stroke: var(--active_stoke, #4ab819);
}

#menu ul.menu-items:hover > li.active:not(:hover) .icon-staff * {
  stroke: #787872;
}

.active .icon-analytics * {
  stroke: var(--active_stoke, #4ab819);
}

#menu ul.menu-items:hover > li.active:not(:hover) .icon-analytics * {
  stroke: #787872;
}

.active .icon-online-store * {
  stroke: var(--active_stoke, #4ab819);
}

#menu ul.menu-items:hover > li.active:not(:hover) .icon-online-store * {
  stroke: #787872;
}
/* Submenu */

#menu ul.menu-items ul {
  display: none;
  margin: 0;
  padding: 0;
  list-style: none;
}

#menu ul.menu-items .active ul {
  display: block;
}
#menu ul.menu-items li .submenu {
  display: none;
}
#menu ul.menu-items li.active .submenu {
  display: block;
  list-style: none;
  padding: 10px 0;
  position: fixed;
  top: 60px;
  left: 90px;
  width: 240px;
  bottom: 0;
  min-height: 510px; /* TODO: revist when reworking on the menu */
  overflow: hidden;
}
#menu ul.menu-items li.active .submenu:hover {
  overflow-y: auto;
}
#menu ul.menu-items li.active .submenu::-webkit-scrollbar {
  width: 0.5rem;
}
#menu ul.menu-items li.active .submenu::-webkit-scrollbar-track:hover {
  background-color: #f4f4f4;
}
#menu ul.menu-items li.active .submenu::-webkit-scrollbar-thumb {
  background-color: #babac0;
  border-radius: 1rem;
}
#menu ul.menu-items li.active .submenu::-webkit-scrollbar-thumb:hover {
  background-color: #a0a0a5;
}
#menu ul.menu-items li.active .submenu::-webkit-scrollbar-button {
  display: none;
}

#sidebar-toggle:not(:checked) + .sidebar-content:hover {
  width: 330px !important;
}

#sidebar-toggle:not(:checked) + .sidebar-content:hover.hide-sub-menu,
.sidebar-content:hover.hide-sub-menu,
.sidebar-content.hide-sub-menu,
#sidebar-toggle:checked + .sidebar-content:hover.hide-sub-menu {
  width: 90px !important;
}

#sidebar-toggle:checked
  + .sidebar-content
  ul.menu-items:hover
  li.active
  .submenu {
  display: none;
}

#sidebar-toggle:not(:checked) + .sidebar-content ul.menu-items li .submenu {
  display: none;
  list-style: none;
  padding: 10px 0;
  position: fixed;
  top: 60px;
  left: 90px;
  width: 240px;
  bottom: 0;
  z-index: 2;
  min-height: 510px;
}

#sidebar-toggle:not(:checked) + .sidebar-content #store-selector {
  display: none;
}

#sidebar-toggle:not(:checked) + .sidebar-content:hover #store-selector {
  display: block;
}
#sidebar-toggle:checked
  + .sidebar-content
  ul.menu-items
  li.active:hover
  .submenu {
  display: flex;
  flex-direction: column;
}
#sidebar-toggle:not(:checked)
  + .sidebar-content
  ul.menu-items
  li:hover
  .submenu {
  display: flex;
  flex-direction: column;
}
#sidebar-toggle:checked + .sidebar-content ul.menu-items li:hover .submenu,
#sidebar-toggle:not(:checked) + .sidebar-content:hover .submenu {
  display: flex;
  flex-direction: column;
  padding: 10px 0;
  position: fixed;
  top: 60px;
  left: 90px;
  width: 240px;
  bottom: 0;
  z-index: 2;
  min-height: 510px;
}

#menu li:hover ul {
  display: block;
  width: 100%;
}

/* icons change on hover in collapsed state */

#menu li.dashboard:hover > a {
  background-image: url("images/home-active.svg");
}

#menu ul.menu-items > li.home-menu:hover ~ li.active.dashboard > a {
  background-image: url("images/home-active.svg");
}
#menu li.catalogue:hover > a * {
  stroke: var(--active_stoke, #4ab819);
}

#menu ul.menu-items > li.home-menu:hover ~ li.active.catalogue > a * {
  stroke: var(--active_stoke, #4ab819);
}

#menu li.orders:hover > a * {
  stroke: var(--active_stoke, #4ab819);
  fill: var(--active_fill, #f4f7f9);
}

#menu ul.menu-items > li.home-menu:hover ~ li.active.orders > a * {
  stroke: var(--active_stoke, #4ab819);
  fill: var(--active_fill, #f4f7f9);
}

#menu li.delivery:hover > a * {
  stroke: var(--active_stoke, #4ab819);
}

#menu ul.menu-items > li.home-menu:hover ~ li.active.delivery > a * {
  stroke: var(--active_stoke, #4ab819);
}

#menu li.marketing:hover > a * {
  stroke: var(--active_stoke, #4ab819);
}

#menu ul.menu-items > li.home-menu:hover ~ li.active.marketing > a * {
  stroke: var(--active_stoke, #4ab819);
}

#menu li.customers:hover > a * {
  stroke: var(--active_stoke, #4ab819);
}

#menu ul.menu-items > li.home-menu:hover ~ li.active.customers > a * {
  stroke: var(--active_stoke, #4ab819);
}

#menu li.settings:hover > a * {
  stroke: var(--active_stoke, #4ab819);
}

#menu ul.menu-items > li.home-menu:hover ~ li.active.settings > a * {
  stroke: var(--active_stoke, #4ab819);
}

#menu li.staff:hover > a * {
  stroke: var(--active_stoke, #4ab819);
}

#menu ul.menu-items > li.home-menu:hover ~ li.active.staff > a * {
  stroke: var(--active_stoke, #4ab819);
}

#menu li.analytics:hover > a * {
  stroke: var(--active_stoke, #4ab819);
}

#menu ul.menu-items > li.home-menu:hover ~ li.active.analytics > a * {
  stroke: var(--active_stoke, #4ab819);
}
#menu li.online-store:hover > a * {
  stroke: var(--active_stoke, #4ab819);
}

#menu ul.menu-items > li.home-menu:hover ~ li.active.online-store > a * {
  stroke: var(--active_stoke, #4ab819);
}

/* icons end */

#sidebar-toggle:checked + .sidebar-content .user-hover {
  display: none;
}

#sidebar-toggle:not(:checked) + .sidebar-content .user-hover {
  display: none;
}

#sidebar-toggle:not(:checked)
  + .sidebar-content
  .accountInfo:hover
  .user-hover {
  display: initial;
  position: absolute;
  top: 0px;
  bottom: 0px;
}

#sidebar-toggle:not(:checked)
  + .sidebar-content
  #store-selector:hover
  .user-hover {
  display: initial;
  position: absolute;
  top: 0px;
  bottom: 0px;
}

#menu ul.menu-items > li:hover:not(.home-menu) {
  box-shadow: -9px 15px 9px 0 rgba(0, 0, 0, 0.16);
  border-bottom-left-radius: 5px;
  border-top-left-radius: 5px;
  background-color: #f4f7f9;
  border-top: none;
}

#menu ul.menu-items > li:hover:not(.home-menu) > a {
  color: #2b3238;
}

#menu ul.menu-items > li.home-menu:hover ~ li.active {
  box-shadow: -9px 15px 9px 0 rgba(0, 0, 0, 0.16);
  border-bottom-left-radius: 5px;
  border-top-left-radius: 5px;
  background-color: #f4f7f9;
}

#menu ul.menu-items > li.home-menu:hover ~ li.active > a {
  color: #2b3238;
}

.accountInfo ul {
  padding: 0;
}

#store-selector ul {
  padding: 0;
}

.user-hover .submenu {
  list-style: none;
  padding-top: 10px;
  position: fixed;
  top: 60px;
  left: -240px;
  width: 240px;
  bottom: 0;
  min-height: 510px;
}

#store-selector .user-hover .submenu {
  top: 108px;
}

.user-hover .submenu ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

.user-hover .submenu li {
  width: 100%;
  text-align: left;
}

.user-hover .submenu li a {
  color: #2b3238;
  text-decoration: none;
  font-weight: normal;
  font-size: 14px;
  display: inline-block;
  padding: 20px 30px;
  width: 100%;
}

.user-hover .submenu li.active a {
  font-weight: 600;
  color: #4ab819;
}

.user-hover .submenu li:after {
  content: "";
  display: block;
  margin: 0 20px;
  border-bottom: 1px solid #fff;
  box-shadow: 0 -1px 0 #e7e9ea;
}

#menu ul.menu-items ul li {
  width: 100%;
  text-align: left;
}

#menu ul.menu-items ul li:after {
  content: "";
  display: block;
  margin: 0 20px;
  border-bottom: 1px solid #fff;
  box-shadow: 0 -1px 0 #e7e9ea;
}

#menu ul.menu-items ul li:last-child:after {
  display: none;
}

#menu ul.menu-items ul li a {
  color: #2b3238;
  text-decoration: none;
  font-weight: normal;
  font-size: 14px;
  display: inline-block;
  padding: 20px 30px;
  width: 100%;
}

#menu ul.menu-items ul li.active a {
  font-weight: 600;
  color: #4ab819;
}

.accountInfo {
  background-color: #fff;
  width: 240px;
  height: 80px;
  display: inline-flex;
  justify-content: space-between;
  border-bottom: 1px solid var(--primary-color);
}

#sidebar-toggle:not(:checked) + .sidebar-content .accountInfo {
  overflow-x: hidden;
}

#sidebar-toggle:not(:checked) + .sidebar-content:hover .accountInfo {
  overflow-x: visible;
}

.store-mobile-wrp {
  display: none;
}
.accountInfo button,
.accountInfo .menu-toggle-container {
  background-color: transparent;
  background-position: center;
  border: transparent;
  outline: none;
  box-shadow: none;
  height: 1.875rem;
  width: 1.875rem;
  padding: 0;
}

.navigationDrawer {
  float: left;
  margin-right: 20px;
  line-height: 27px;
  position: relative;
  z-index: 10;
}
.accountInfo button,
.accountInfo .menu-toggle-container:not(:last-child) {
  margin-right: 1.5rem;
}

.submenu-info {
  margin-top: 20px;
  margin-bottom: 40px;
}

#menu .accountInfo .DropDown {
  height: 100%;
}

#menu .accountInfo .dropdown-toggle {
  display: block;
  height: 100%;
  margin: 0;
  padding: 0;
  cursor: pointer;
}

#menu .accountInfo .dropdown-menu {
  margin-top: -20px;
}

#menu .LanguageSelector .dropdown-menu {
  z-index: 3;
}

#menu .accountInfo .NotificationBox {
  z-index: 3;
}
#sidebar-toggle:not(:checked) + .sidebar-content .accountInfo .NotificationBox {
  display: none;
}

#sidebar-toggle:not(:checked)
  + .sidebar-content:hover
  .accountInfo
  .NotificationBox {
  display: block;
}

#menu .UserActions .dropdown-menu {
  z-index: 4;
}

#menu .sidebar-content.shrink:hover {
  width: 90px;
}

#sidebar-toggle:checked
  + .sidebar-content
  ul.menu-items
  li.showSubmenu
  .submenu {
  display: flex !important;
  flex-direction: column !important;
}

#menu .sidebar-content::-webkit-scrollbar {
  width: 0.5rem;
}
#menu .sidebar-content::-webkit-scrollbar-track:hover {
  background-color: #f4f4f4;
}
#menu .sidebar-content::-webkit-scrollbar-thumb {
  background-color: #babac0;
  border-radius: 1rem;
}
#menu .sidebar-content::-webkit-scrollbar-thumb:hover {
  background-color: #a0a0a5;
}
#menu .sidebar-content::-webkit-scrollbar-button {
  display: none;
}

@media screen and (min-width: 1024px) {
  .verify-warning #menu ul.menu-items li.active .submenu,
  .verify-warning
    #sidebar-toggle:not(:checked)
    + .sidebar-content
    ul.menu-items
    li
    .submenu,
  .verify-warning
    #sidebar-toggle:checked
    + .sidebar-content
    ul.menu-items
    li:hover
    .submenu,
  .verify-warning
    #sidebar-toggle:not(:checked)
    + .sidebar-content:hover
    .submenu,
  .verify-warning .user-hover .submenu {
    top: 97px;
  }
}

@media screen and (max-width: 1023px) {
  .navigationDrawer {
    margin-right: unset;
    margin-left: 1rem;
  }
  .mobile-menu #menu {
    height: 100%;
  }
  #menu .sidebar-content {
    overflow: auto;
    width: 330px !important;
  }
  #menu ul.menu-items li.active .submenu {
    top: 0;
  }
  #sidebar-toggle:not(:checked) + .sidebar-content:hover.hide-sub-menu,
  .sidebar-content:hover.hide-sub-menu,
  .sidebar-content.hide-sub-menu,
  #sidebar-toggle:checked + .sidebar-content:hover.hide-sub-menu {
    width: 330px !important;
  }
  #sidebar-toggle:checked + .sidebar-content ul.menu-items li:hover .submenu,
  #sidebar-toggle:not(:checked) + .sidebar-content:hover .submenu {
    top: 0;
  }
}
@media screen and (max-width: 640px) {
  .navigationDrawer {
    margin-right: unset;
    margin-left: 1rem;
  }
  .customize {
    padding: 0.5rem;
  }
  #menu .sidebar-content {
    height: calc(100vh - 90px);
    overflow: auto;
    width: 330px !important;
  }
  #menu .sidebar-content .dashboard {
    display: none;
  }

  .accountInfo button,
  .accountInfo .menu-toggle-container:not(:last-child) {
    margin-right: 1rem;
  }
  .store-mobile-wrp {
    display: block;
    background-color: transparent;
    background-position: center;
    border: transparent;
    outline: none;
    box-shadow: none;
    height: 1.875rem;
    width: 1.875rem;
    padding: 0;
    margin-right: 1rem;
  }

  .store-mobile-wrp:hover g {
    stroke: #4ab819;
  }
  #menu ul.menu-items li.active .submenu {
    top: 90px;
  }
  #sidebar-toggle:not(:checked) + .sidebar-content:hover.hide-sub-menu,
  .sidebar-content:hover.hide-sub-menu,
  .sidebar-content.hide-sub-menu,
  #sidebar-toggle:checked + .sidebar-content:hover.hide-sub-menu {
    width: 330px !important;
  }
  #sidebar-toggle:checked + .sidebar-content ul.menu-items li:hover .submenu,
  #sidebar-toggle:not(:checked) + .sidebar-content:hover .submenu {
    top: 90px;
  }
  #menu ul.menu-items li.active .submenu {
    overflow-y: auto;
  }
}
