.hcm-tooltip-base {
  border-radius: 0.625rem;
  transition: all 0.1s ease-out;
}

.coach-card-wrp {
  padding: 4px 4px 6px 0.875rem;
  display: flex;
  flex-direction: column;
}

.coach-card-wrp .close-button {
  align-self: flex-end;
  font-size: 1.25rem;
  color: #80959d;
  cursor: pointer;
  line-height: 1;
}

.coach-card-title {
  font-size: 1.125rem;
  color: #181912;
  font-weight: 600;
  margin-bottom: 0.5rem;
}

.coach-card-desc {
  font-size: 0.875rem;
  color: #181912;
  line-height: 1.25rem;
  font-weight: 400;
  margin-bottom: 2.5rem;
  max-width: 16rem;
}

.coach-card-indicator-btn-wrp {
  display: flex;
  align-items: baseline;
  justify-content: space-between;
}

.coach-card-indicators-wrp {
  display: flex;
}

.coach-card-indicator {
  height: 6px;
  width: 6px;
  background-color: #787872;
  border-radius: 50%;
  cursor: pointer;
}

.coach-card-indicator:not(:last-child) {
  margin-right: 4px;
}

.coach-card-selected-indicator {
  width: 0.75rem;
  background-color: var(--primary-color);
  border-radius: 5px;
}

.coach-card-btn {
  color: #fff;
  font-size: 0.875rem;
  font-weight: 600;
  background-color: var(--primary-color);
  padding: 6px 1rem;
  border-radius: 4px;
  text-align: center;
  white-space: nowrap;
}

.harsh-coach-mark {
  transition: all 0.1s ease-out;
}
