/* Draftjs overrides */

.RichEditor .public-DraftEditorPlaceholder-root {
  color: #aeb9bd;
}

.RichEditor-hidePlaceholder .public-DraftEditorPlaceholder-root {
  display: none;
}

.RichEditor .public-DraftEditor-content,
.RichEditor .public-DraftEditorPlaceholder-root {
  cursor: text;
  padding: 15px;
  font-size: 0.875rem;
}

.RichEditor .public-DraftEditor-content {
  min-height: 280px;
}
/* End of overrides */

.RichEditor {
  border: solid 1px var(--border-color);
}

.RichEditor .editor-actions {
  height: 70px;
  background-color: #fafafa;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1rem;
  font-family: serif;
  color: #181912;
}

.RichEditor .editor-action {
  cursor: pointer;
  width: 24px;
  height: 24px;
  border-width: 0;
  margin: 0 5px;
  border-radius: 2px;
  transition: background 0.2s ease-in-out, color 0.2s ease-in-out;
}

.RichEditor .editor-action:hover {
  /*background-color: #eee;*/
  color: #ff6e6d;
}

.RichEditor .icon img {
  height: 18px;
}
