:root {
  --border-color: #dadee0;
  --primary-text-color: #2b3238;
  --secondary-text-color: #80959d;
  --primary-color: #4ab819;
  --secondary-action-color: #f3f5f6;
  --separator-color: #eaefef;
  --bg-color: #fbfcfc;
  --disabled-color: #f4f7f9;
  --black-transparent: #00000099;
}
html {
  font-size: 16px;
}

body {
  font-family: "Open Sans", sans-serif;
  color: #2b3238;
  margin: 0;
  box-sizing: border-box;
  overscroll-behavior-y: none;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body * {
  box-sizing: inherit;
}

input,
textarea,
select,
button {
  font-family: inherit;
}

:focus {
  outline: none;
}

.gif-loader-wrp {
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
}

.gif-loader-wrp img {
  height: 120px;
}

/* Page Container */
#app .app-main-wrp {
  display: flex;
  min-height: 100vh;
  max-width: 100vw;
  overflow-x: hidden;
}
#app main {
  min-width: 320px;
  box-shadow: 2px 15px 9px 0 rgba(0, 0, 0, 0.15);
  padding: 30px 40px;
  z-index: 1;
}
#app .home-dashboard {
  padding: 0;
}
#app .home-dashboard .operations-dashboard {
  padding: 0 40px 30px;
}
#app main > div {
  position: relative;
}

#app main {
  flex: 0 0 100%;
}

img {
  max-width: unset;
}

@media (min-width: 1024px) {
  #app main {
    flex: 1 1;
  }
  #app #main-dash-content {
    margin-left: 13.75rem;
  }
}

.fullWidthPage #main {
  background: url("./bg-footer.svg") repeat-x -42px bottom;
  min-height: calc(100% - 50px);
  background-position: bottom;
  padding: 15px 25px;
}
h1 {
  margin: 0 0 38px;
  font-size: 1.85rem;
  font-weight: bold;
  line-height: 0.93;
  letter-spacing: -0.7px;
}

h3 {
  font-size: 18px;
  font-weight: 600;
}

h1.title {
  margin-bottom: 40px;
  line-height: 1;
}

strong {
  font-weight: bold;
}

a {
  color: var(--primary-color);
  cursor: pointer;
  text-decoration: none;
}

img:not([src]) {
  display: none;
}

.flex {
  display: flex;
}

.flex-around {
  display: flex;
  justify-content: space-between;
  flex: 1; /* This is given so that elements with this class can be nested */
}

.flex-vertical {
  flex-direction: column;
}

.flex-item {
  flex: 1;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.plus-icon {
  height: 10px;
  width: 10px;
  background-image: url("./plus.svg");
  display: inline-block;
  margin-right: 8px;
}

small {
  font-size: 0.75rem;
}

.text-muted {
  color: #80959d;
}

.text-nowrap {
  white-space: nowrap;
}

.hidden {
  display: none;
}

.red {
  color: #eb8181;
}

.green {
  color: var(--primary-color);
}

.zero-font {
  font-size: 0;
}

.grid {
  display: grid;
}

.bold {
  font-weight: 600;
}

.capitalize {
  text-transform: capitalize;
}
.desktop-menu {
  display: flex;
  flex-direction: column;
  position: fixed;
  left: 0;
  height: calc(100vh - 3.2625rem);
  z-index: 2;
}
.verify-warning .desktop-menu {
  height: calc(100vh - 6.0625rem);
}
.mobile-menu {
  display: none;
}
.recharge-mobile-wrp {
  display: none;
}
.top-navbar {
  position: sticky;
  top: 0;
  z-index: 4;
}
@media screen and (min-width: 768px) {
  /* Media Object */
  .media {
    display: flex;
  }
  .media-body {
    flex: 1;
  }
}
@media screen and (max-width: 840px) {
  h1.title {
    font-size: 1.5rem;
    margin-bottom: 30px;
    line-height: 26px;
  }
}

@media screen and (max-width: 480px) {
  #app main {
    padding: 1rem;
  }
  .xs-hidden {
    display: none;
  }
  h1.title {
    font-size: 1.125rem;
    line-height: 26px;
  }
  h1 {
    font-size: 1.125rem;
    line-height: 26px;
  }
}

@media screen and (max-width: 640px) {
  #app .home-dashboard .operations-dashboard {
    padding: 30px 0;
    padding-top: 0;
  }
  .recharge-mobile-wrp {
    display: flex;
    width: 13.75rem;
    background-color: #181912;
  }
  .recharge-mobile-wrp .recharge-widget {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-left: 1px solid #000;
    width: calc(100% - 92px);
    padding: 0 1rem;
  }
  .recharge-mobile-wrp .recharge-button {
    color: #4ab819;
    font-size: 0.75rem;
    background-color: #181912;
    border: 1px solid #4ab819;
    padding: 6px 1rem;
    border-radius: 0.25rem;
  }
  .recharge-mobile-wrp .balance-indication h3 {
    margin: 0;
    color: #fff;
    font-size: 0.75rem;
  }
  .recharge-home-wrp {
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1rem 3.5rem 1rem 1.5rem;
  }
  .recharge-home-wrp a {
    color: #80959d;
    text-decoration: none;
    width: 100%;
    display: inline-block;
    line-height: 1;
    background-repeat: no-repeat;
    background-position-y: 20px;
    background-position-x: 50%;
    word-break: break-word;
  }
  .recharge-home-wrp a img {
    height: 2rem;
  }
}

@media screen and (max-width: 1023px) {
  .app-main-wrp .navigationDrawer {
    display: none;
  }
  .desktop-menu {
    display: none;
  }
  .mobile-menu {
    display: flex;
    flex-direction: column;
    position: fixed;
    top: 0;
    z-index: 10;
    left: 0;
    transform: translateX(-110%);
    transition: transform 0.5s ease 0s,
      background-color 0s cubic-bezier(0, 0, 0.91, 2.4) 0.2s;
    width: 100%;
    height: 100%;
  }
  .mobile-menu.show-mobile-menu {
    transform: translateX(0);
    background-color: rgba(0, 0, 0, 0.6);
  }
}

/* padding classes */
.p-1 {
  padding: 1rem;
}
.p-2 {
  padding: 2rem;
}
.p-3 {
  padding: 3rem;
}
.p-4 {
  padding: 4rem;
}
.px-1 {
  padding: 0 1rem;
}
.px-2 {
  padding: 0 2rem;
}
.px-3 {
  padding: 0 3rem;
}
.px-4 {
  padding: 0 4rem;
}
.py-1 {
  padding: 1rem 0;
}
.py-2 {
  padding: 2rem 0;
}
.py-3 {
  padding: 3rem 0;
}
.py-4 {
  padding: 4rem 0;
}
.pl-1 {
  padding-left: 1rem;
}
.pl-2 {
  padding-left: 2rem;
}
.pl-3 {
  padding-left: 3rem;
}
.pl-4 {
  padding-left: 4rem;
}
.pl-5 {
  padding-left: 5rem;
}
.pr-1 {
  padding-right: 1rem;
}
.pr-2 {
  padding-right: 2rem;
}
.pr-3 {
  padding-right: 3rem;
}
.pr-4 {
  padding-right: 4rem;
}
.pr-5 {
  padding-right: 5rem;
}


/* margin classes */
.m-1 {
  margin: 1rem;
}
.m-2 {
  margin: 2rem;
}
.m-3 {
  margin: 3rem;
}
.m-4 {
  margin: 4rem;
}
.mx-1 {
  margin: 0 1rem;
}
.mx-2 {
  margin: 0 2rem;
}
.mx-3 {
  margin: 0 3rem;
}
.mx-4 {
  margin: 0 4rem;
}
.my-1 {
  margin: 1rem 0;
}
.my-2 {
  margin: 2rem 0;
}
.my-3 {
  margin: 3rem 0;
}
.my-4 {
  margin: 4rem 0;
}
.mt-1 {
  margin-top: 1rem;
}
.mt-2 {
  margin-top: 2rem;
}
.mt-3 {
  margin-top: 3rem;
}
.mt-4 {
  margin-top: 4rem;
}
.mt-5 {
  margin-top: 5rem;
}
.mb-1 {
  margin-bottom: 1rem;
}
.mb-2 {
  margin-bottom: 2rem;
}
.mb-3 {
  margin-bottom: 3rem;
}
.mb-4 {
  margin-bottom: 4rem;
}
.mb-5 {
  margin-bottom: 5rem;
}
.ml-1 {
  margin-left: 1rem;
}
.ml-2 {
  margin-left: 2rem;
}
.ml-3 {
  margin-left: 3rem;
}
.ml-4 {
  margin-left: 4rem;
}
.ml-5 {
  margin-left: 5rem;
}
.mr-1 {
  margin-right: 1rem;
}
.mr-2 {
  margin-right: 2rem;
}
.mr-3 {
  margin-right: 3rem;
}
.mr-4 {
  margin-right: 4rem;
}
.mr-5 {
  margin-right: 5rem;
}
