.menu-wrp {
  background-color: #181912;
  height: 100%;
  padding: 0.5rem;
  width: 13.75rem;
  overflow-y: auto;
}
.menu-wrp::-webkit-scrollbar {
  display: none;
}

.menu-wrp .menu-items {
  list-style: none;
  padding: 0.5rem;
  margin: 0;
}

.menu-wrp .menu-items .menu-item {
  width: 12.5rem;
  padding: 0.5rem;
  cursor: pointer;
  font-size: 0.875rem;
  line-height: 1rem;
  text-transform: capitalize;
  color: #b8b8b5;
  margin-bottom: 8px;
  display: flex;
  align-items: center;
}

.menu-wrp .menu-items .menu-item.main-menu {
  display: block;
}

.menu-wrp .menu-items .menu-item.submenu {
  padding-left: 2.125rem;
  display: flex;
}

.menu-wrp .menu-items .menu-item svg {
  width: 1.5rem;
}

.menu-wrp .menu-items .menu-item span {
  margin-left: 0.5rem;
}

.menu-wrp .menu-items .menu-item:hover,
.menu-wrp .menu-items .menu-item.active {
  color: #fff;
}

.menu-wrp .menu-items .menu-item:hover > svg g path,
.menu-wrp .menu-items .menu-item.active > svg g path {
  stroke: var(--primary-color);
}

.menu-wrp .menu-items .menu-item:hover > svg g,
.menu-wrp .menu-items .menu-item.active > svg g {
  stroke: var(--primary-color);
}

.menu-wrp .menu-items .menu-item.hide {
  display: none;
}

.main-menu hr {
  border-bottom: 0.5px solid #b8b8b5;
  opacity: 0.2;
  margin: 1.25rem 0 0 0;
  padding: 0;
}


.ios-wrp img {
  height: 1.975rem;
  width: 5.5rem;
  margin: auto;
}

.android-wrp img {
  height: 2.9rem;
  width: 6.4rem;
  margin: auto;
}

.download-app-container {
  display: flex;
  flex-direction: column;
  padding: 0 1rem 1rem;
  background: #181912;
  width: 13.75rem;
}

.download-app-container p {
  font-size: 14px;
  line-height: 16px;
  color: #fff;
}

.download-app-icon {
  display: flex;
  align-items: center;
  justify-content: right;
}

@media screen and (max-width: 480px) {
  .menu-wrp {
    height: calc(100% - 3rem);
  }
  .menu-wrp .menu-items .menu-item svg {
    width: 1.25rem;
  }
}
