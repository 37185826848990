form {
  max-width: 32rem;
}

.field {
  margin-bottom: 20px;
  position: relative;
}
#portal .field {
  margin-bottom: 18px;
}

.field label {
  display: inline-block;
  color: #2b3238;
  font-size: 0.75rem;
  line-height: 1.41;
  text-transform: uppercase;
  font-weight: 600;
  margin-bottom: 10px;
  letter-spacing: -0.2px;
  cursor: pointer;
  user-select: none;
  transition: color 0.2s ease-in-out;
}
.helperTextContainer {
  position: relative;
}

.helperIcon {
  cursor: pointer;
  margin-bottom: 10px;
}
.helperText {
  position: absolute;
  z-index: 3;
  background: rgb(120, 120, 114);
  padding: 10px;
  border-radius: 4px;
  color: #fff;
  font-size: 12px;
  line-height: 17px;
  width: 200px;
}
.helperText::before {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  transform: translate(10%, 4%);
  bottom: 100%;
  width: 0;
  height: 0;
  border-bottom: 10px solid rgb(120, 120, 114);
  border-right: 10px solid transparent;
  border-left: 10px solid transparent;
}
.wrapper-container {
  display: flex;
  align-items: center;
}
label.inline {
  display: inline-block;
  text-transform: capitalize;
  color: #80959d;
  line-height: 1.5;
  vertical-align: top;
  margin: 0;
}

.input {
  display: flex;
  position: relative;
}

input:read-only {
  cursor: not-allowed;
}

input:-webkit-autofill {
  animation-name: onAutoFillStart;
  transition: background-color 50000s ease-in-out 0s;
}

select:disabled {
  cursor: not-allowed;
}

@keyframes onAutoFillStart {
  from {
    /**/
  }
  to {
    /**/
  }
}

input[type="text"],
input[type="password"],
input[type="number"],
input[type="email"],
input[type="url"],
input[type="tel"],
select {
  font-size: 0.875rem;
  background-color: #fff;
  padding: 0 15px;
  border: 1px solid var(--border-color);
  border-radius: 2px;
  height: 44px;
  width: 100%;
  vertical-align: middle;
  flex: 1;
  color: #2b3238;
}

input[type="text"],
input[type="password"],
input[type="number"],
input[type="email"],
input[type="url"],
input[type="tel"] {
  font-weight: 600;
}

.field > .description {
  margin-top: 10px;
  margin-bottom: 0;
  color: #80959d;
  font-size: 0.75rem;
  letter-spacing: -0.1px;
}

input::placeholder,
textarea::placeholder {
  color: #aeb9bd;
  font-size: 0.875rem;
  font-weight: normal;
}

.input-addon {
  display: flex;
  align-items: center;
  height: 44px;
  padding: 0 15px;
  background-color: #f3f5f6;
  border: 1px solid var(--border-color);
  font-size: 0.875rem;
  font-weight: 600;
  vertical-align: middle;
}

.input-addon:first-child {
  border-right: none;
  border-radius: 2px 0 0 2px;
}

.input-addon:last-child {
  border-left: none;
  border-radius: 0 2px 2px 0;
}

.input-addon + input {
  border-radius: 0;
  /*width: auto;*/
}

input[type="submit"],
.button {
  height: 40px;
  padding: 0 20px;
  font-weight: bold;
  font-size: 0.875rem;
  text-transform: capitalize;
  background-color: #f3f5f6;
  color: #2b3238;
  border: 1px solid transparent;
  border-radius: 2px;
  letter-spacing: -0.3px;
  cursor: pointer;
  text-decoration: none;
  line-height: 40px;
  display: inline-block;
  text-align: center;
}

button {
  border: 1px solid transparent;
  display: inline-block;
  cursor: pointer;
}

button[disabled] {
  cursor: not-allowed;
}

.primary {
  background-color: var(--primary-color) !important;
  color: #fff !important;
}
.button {
  min-width: 110px;
}
input:disabled {
  opacity: 0.6;
  cursor: not-allowed;
}

input[type="submit"] + button,
.button-white {
  font-size: 0.875rem;
  font-weight: bold;
  background-color: #f3f5f6;
  color: #2b3238;
  margin-left: 10px;
  letter-spacing: -0.3px;
}

input,
textarea,
button {
  transition: all 200ms ease-in-out;
  vertical-align: middle;
}

input:focus,
textarea:focus {
  border-color: var(--primary-color);
  outline: none;
}

input.focus-none:focus {
  border: 1px solid var(--border-color);
}

input:invalid {
  box-shadow: none;
}

input[type="file"]:focus + .file-label {
  border-color: var(--primary-color);
}

textarea {
  font-size: 0.875rem;
  width: 100%;
  height: 180px;
  border: 1px solid var(--border-color);
  border-radius: 2px;
  padding: 12px 15px;
  background-color: #fff;
}

.form-error {
  color: #ea353a;
  text-align: center;
  margin-bottom: 20px;
  font-size: 14px;
}

.labelWrap {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

form label.thin {
  color: #80959d;
  font-weight: normal;
}

form .form-buttons > button:not(:first-child) {
  margin-left: 10px;
}
input[type="color"] {
  cursor: pointer;
}

@media screen and (max-width: 480px) {
  input[type="color"],
  input[type="date"],
  input[type="datetime"],
  input[type="datetime-local"],
  input[type="email"],
  input[type="month"],
  input[type="number"],
  input[type="password"],
  input[type="search"],
  input[type="tel"],
  input[type="text"],
  input[type="time"],
  input[type="url"],
  input[type="week"],
  select:focus,
  textarea {
    font-size: 16px;
  }
  .helperText {
    left: unset;
    top: unset;
    transform: unset;
    width: 120px;
  }
}
